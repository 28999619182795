import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
// const root = ReactDOMClient.createRoot(document.getElementById('root'));

// root.render(
//   // <React.StrictMode>
//   //   <App />
//   // </React.StrictMode>
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
// );
const googleApiToken = process.env.REACT_APP_GOOGLE_API_TOKEN;
console.log(googleApiToken); // Ensure this prints the correct token
root.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId={googleApiToken}>
      <App />
    </GoogleOAuthProvider>
  </BrowserRouter>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

