import React, { useState, useEffect } from "react";
 
import { Link } from "react-router-dom";
import axios from "axios";
import ProfilePicture from "./ProfilePicture";

export default function SocialMedia() {
  const [isEditing, setIsEditing] = useState(false);

  const [formData, setFormData] = useState({
    instaLink: "",
    spotifyLink: "",
    fbLink: "",
    youtubeLink: "",
    twitterLink: ""
  });

  const [instaLink, setInstaLink] = useState("");
  const [spotifyLink, setSpotifyLink] = useState("");
  const [fbLink, setFbLink] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [fullName, setFullName] = useState("");

  const [originalData, setOriginalData] = useState({});

  const handleEditClick = () => {
    setIsEditing(true);
    setInstaLink();
    setSpotifyLink();
    setFbLink();
    setYoutubeLink();
    setTwitterLink();
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    setOriginalData({ ...formData });
  };

  const handleCancelClick = () => {
    setFormData({ ...originalData });
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const [inputValue, setInputValue] = useState("");
  // const [isValid, setIsValid] = useState(true);

  // Regular expression for social media URLs
  // const socialMediaRegex = /^(https?:\/\/)?(www\.)?(facebook|twitter|instagram|linkedin|tiktok|youtube|pinterest)\.com\/[A-Za-z0-9_.-]+$/;

  // const handleChange = (event) => {
  //   const value = event.target.value;
  //   setInputValue(value);
  //   setIsValid(socialMediaRegex.test(value));
  // };

  //------API Inegration--------
  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await axios.get(
          "REACT_BACKEND_URL/aa/users?id=3610e2064b884811b13c438bec986c"
        );
        const userData = response.data.data[0];

        //login the API response
        console.log("API response data", userData);

        setFullName(userData.full_name || "Artist Name");
        setInstaLink(userData.instagram || "");
        // setInstaLink("");
        setSpotifyLink(userData.spotify || "");
        setFbLink(userData.facebook || "");
        setYoutubeLink(userData.youtube || "");
        setTwitterLink(userData.twitter || "");

        setOriginalData({
          instaLink: userData.instagram || "",
          spotifyLink: userData.spotify || "",
          fbLink: userData.facebook || "",
          youtubeLink: userData.youtube || "",
          twitterLink: userData.twitter || "",
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    getUserInfo();
  }, []);

  return (
    <>
      <div className="flex">
        <button
          className="my-5 text-[#FEC961] text-[1.6rem] w-auto h-[5rem] ml-[12.5%]"
          style={{ fontFamily: "Poppins, sanSerif" }}
        >
          <Link to="/account-details">Account Details</Link>
        </button>
        <button
          className="my-5 text-[#FF6C2F] text-[1.6rem] w-auto h-[5rem] ml-[4%]"
          style={{ fontFamily: "Poppins, sanSerif" }}
        >
          <Link to="/social-media">Social Media</Link>
          <hr />
        </button>
        <button
          className="my-5 text-[#FEC961] text-[1.6rem] w-auto h-[5rem] m-auto ml-[4%]"
          style={{ fontFamily: "Poppins, sanSerif" }}
        >
          <Link to="/bank-details">Bank Details</Link>
        </button>
      </div>
      <div className="container1">
        <div className="profileBar w-[28%] bg-[#ffffff0d] rounded-md">
          <ProfilePicture artistName={fullName} />
        </div>
        <div className="formDetails">
          <div>
            <label
              htmlFor=""
              className="flex font-[Poppins] text-[#FFFFFF] mt-[1%] w-[80%] flex-row"
            >
              Instagram
            </label>
            <div>
              <input
                type="url"
                className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
                placeholder="https://instagram.com/xyz"
                onChange={handleInputChange}
                readOnly={!isEditing}
                value={instaLink}
              />
              {/* {!isValid && (
                <p className="error-message">
                  Please enter a valid social media link.
                </p>
              )} */}
            </div>
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Spotify
            </label>
            <input
              type="url"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder="https://open.spotify.com/artist/4YRxDV8wJFPHPTeXepOstw"
              onChange={handleInputChange}
              readOnly={!isEditing}
              value={spotifyLink}
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Facebook
            </label>
            <input
              type="url"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder="https://www.facebook.com/xyz"
              onChange={handleInputChange}
              readOnly={!isEditing}
              value={fbLink}
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              YouTube
            </label>
            <input
              type="url"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder="https://www.youtube.com/xyz"
              onChange={handleInputChange}
              readOnly={!isEditing}
              value={youtubeLink}
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Twitter
            </label>
            <input
              type="url"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder=""
              onChange={handleInputChange}
              readOnly={!isEditing}
              value={twitterLink}
            />
          </div>
          <div>
            {isEditing ? (
              <div className="flex justify-end mt-[14%]">
                <button
                  className="text-[#FFFFFF] text-[100%] w-[6rem] h-[3rem] bg-[#6E6E6E] rounded-md hover:bg-[#484848] active:bg-[#484848]"
                  style={{
                    fontFamily: "Poppins, sanSerif",
                    padding: "8px 16px",
                    marginBottom: "40px",
                  }}
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
                <button
                  className="mb-[14%] text-[#FFFFFF] text-[100%] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md ml-[1%] hover:bg-[#d95a25] active:bg-[#c15122]"
                  style={{
                    fontFamily: "Poppins, sanSerif",
                    padding: "8px 16px",
                  }}
                  onClick={handleSaveClick}
                >
                  Save
                </button>
              </div>
            ) : (
              <div className="flex justify-end mt-[14%]">
                <button
                  className="text-[#FFFFFF] text-[100%] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md mb-[14%] hover:bg-[#d95a25] active:bg-[#c15122]"
                  style={{
                    fontFamily: "Poppins, sanSerif",
                    padding: "8px 16px",
                  }}
                  onClick={handleEditClick}
                >
                  Edit
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
