import React from 'react'
import { useState } from 'react'
import { publicRouteLink } from '../constant/Routes';
import TermsCheckbox from './TermsCheckbox';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MainContext } from '../context/main';
import { useContext } from 'react';
import { toast } from "react-hot-toast";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from 'react-router-dom';

const SignUp1 = ({ onClose }) => {
    const [ph, setPh] = useState("");
    const [isValidEmail, setValidEmail] = useState(true);
    const [isPhoneExists, setIsPhoneExists] = useState(false);
    const { formData, handleFormData } = useContext(MainContext);
    const { countryCode, setCountryCode, mob, setMob, buttonName, buttonValue, updateButtonInfo } = useContext(MainContext);
    const [user, setUser] = useState([]);
    const [profile, setProfile] = useState('');
    const navigate = useNavigate();

    const sendOTP = async (e) => {
        onClose && onClose();
        updateButtonInfo('Send OTP', 'SignUp');
        try {
            if (!formData?.phone) {
                toast.error('Please enter your number');
                return;

            }
            const data = {
                "mob": mob,
                "countryCode": '+' + countryCode,
            };
            formData.phone = data.mob;
            formData.country_code = data.countryCode;

            const resp = await fetch('REACT_BACKEND_URL/aa/users/send-otp', {
                method: "POST",
                headers: {
                    "Content-type": "application/json"
                },
                body: JSON.stringify(data)
            });
            if (resp.ok) {
                toast.success("OTP sent successfully!");
                navigate(publicRouteLink.OTP_VERIFICATION);
                return;
            }
            else {
                toast.error("OTP not sent. Please try again!");
                return;
            }
        }
        catch (error) {
            console.log("Error from sendOTP function:", error);
            return
        }
    }

    const checkPhoneNumber = async () => {
        try {
            const response = await fetch(`REACT_BACKEND_URL/aa/users/${mob}`);
            const respData = await response.json();

            if (respData.status == 200) {
                await sendOTP();
            } else if (respData.status == 409) {
                setIsPhoneExists(true);
                return;
            }
        } catch (err) {
            console.error("Error from checkPhoneNumber function:", err.message);
            if (err.response) {
                console.error("Error response:", err.response.data);
            }
        }
    }

    const handleGoogleLogin = async () => {
        try {
            if (user) {
                axios
                    .get(
                        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
                        {
                            headers: {
                                Authorization: `Bearer ${user.access_token}`,
                                Accept: "application/json",
                            },
                        }
                    )
                    .then((res) => {
                        setProfile(res.data);
                    })
                    .catch((err) => console.log(err));
            }
            login();
        }
        catch (error) {

        }
    }

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setUser(codeResponse)
            navigate(publicRouteLink.DASHBOARD);
            console.log(codeResponse);
        },
        onError: (error) => console.log("Login Failed:", error)
    });

    return (
        <div className="flex items-center justify-center bg-[#1A1A1A] px-16 py-12">
            <form className="bg-[#1A1A1A] text-white rounded-mdF w-96">
                <h1 className="text-3xl font-bold text-center">Create your account</h1>
                <p className='mt-6'>Create an account now and be the next sensation in the enterainment industry</p>

                <div className="pt-3">
                    <label className="block text-white text-sm font-normal" htmlFor="name">Full Name
                        <span className='text-[#FF6C2F]'>*</span>
                    </label>
                    <input
                        name='fullName'
                        className="w-full outline-none bg-white text-black rounded-md pl-2 h-12"
                        type="text" id="name" value={formData?.fullName}
                        onChange={handleFormData}
                        required
                    />

                    <label className="block text-white text-sm font-normal pt-4" htmlFor="ph">Mobile Number
                        <span className='text-[#FF6C2F]'>*</span>
                    </label>
                    <PhoneInput
                        inputProps={{
                            name: 'phone',
                            required: true
                        }}
                        inputClass="w-full"
                        country={"in"}
                        value={ph}
                        required
                        onChange={(phone, country, e) => {
                            let newPh = phone.split(country.dialCode);
                            phone = newPh[1];
                            setMob(phone);
                            setCountryCode(country.dialCode);
                            handleFormData(e)
                        }}
                        className="text-black"
                    />

                    <label className="block text-white text-sm font-normal pt-4" htmlFor="email">Email Address
                        <span className='text-[#FF6C2F]'>*</span>
                    </label>
                    <input
                        name='email'
                        className={`w-full outline-none bg-white text-black rounded-md pl-2 h-12
                         ${isValidEmail} ? '' : 'border-red-500' `}
                        type="email"
                        id="email"
                        value={formData?.email}
                        onChange={handleFormData}
                        required
                    />
                    {!isValidEmail && (
                        <p className="text-red-500 text-sm mt-1">
                            Please enter a valid email address.
                        </p>
                    )}
                    <TermsCheckbox />

                    <button className="w-full bg-[#FF6C2F] text-white h-12 rounded-md text-sm font-medium mt-3"
                        id='sign-in-button'
                        type="button"
                        onClick={checkPhoneNumber}
                    >
                        Get Started
                    </button>

                    {isPhoneExists &&
                        <p className='text-[#FA3235]'>The user account already exists; please sign in.</p>
                    }

                    <div className='my-1 text-center'>or</div>
                    <button
                        className="w-full bg-[#262626] text-white h-12 rounded-md text-sm font-medium mt-3"
                        type="button"
                        onClick={handleGoogleLogin}
                    >
                        <span className='flex flex-row justify-center items-center'>
                            <img src='assets\google-icon.svg' alt='google-icon' className='pr-2' />
                            Sign in with Google
                        </span>
                    </button>

                    <div className='flex flex-row justify-between items-center mt-5'>
                        <p className='items-start'>Already have an account?</p>
                        <a className='bg-[#262626] text-[#FF6C2F] w-28 h-9 flex items-center justify-center rounded-md' href={publicRouteLink.SIGNIN}>Login Now</a>
                    </div>

                </div>
            </form>
        </div>
    )
}

export default SignUp1
